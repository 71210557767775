import React from "react"
import { graphql } from "gatsby"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from '../components/Button/ButtonGroupMap';
import TheaterVideo from "@components/Theater/TheaterVideo";
import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from 'gatsby'

import {
    Image,
    Video,
    Transformation,
    CloudinaryContext,
} from "cloudinary-react"


const BackToBusinessPage = ({ data, pageContext }) => {
    
    const post = data.allUniquePagesJson.nodes[0];

    return (
        <SharedStateProvider>
            <Layout brand={pageContext.brand}>
                <SEO 
                    title={post.metaTitle} 
                    description={post.metaDescription} 
                />
                <div className="joshua-tree-content">
                    <div className="b2b-banner">
                        <h1>{post.heading}</h1>
                    </div>

                    <div className="b2b-top-section">

                        <div className="columns">
                            <div className="column is-5"></div>
                            <div className="column">
                                {post.subHeadingBlurb && 
                                    <p className="top-p"> {post.subHeadingBlurb} </p>
                                }
                            </div>
                            <div className="column is-5"></div>
                        </div>


                        <div className="treatment-grid">
                            <div className="columns">
                                <div className="column is-6"></div>

                                <div className="treatment-column column">
                                    <div className="icon-wrapper"><img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/doors-icon-blue.svg" /></div>
                                    <MarkdownViewer markdown={post.iconOneHeading} />
                                    <p> {post.iconOneBlurb} </p>
                                </div>
                                <div className="column is-1"></div>

                                <div className="treatment-column column">
                                    <div className="icon-wrapper"><img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/safety-icon-blue.svg" /></div>
                                    <MarkdownViewer markdown={post.iconTwoHeading} />
                                    <p> {post.iconTwoBlurb} </p>
                                </div>

                                <div className="column is-6"></div>
                            </div>
                        </div>

                    </div>

                    <ImageTexts 
                        imageTexts={post.imageTextOne} 
                    />

                    <section className="body-section b2b-body">
                        <div className="b2b-body wrapper columns">
                            <div className="column is-3"></div>

                            <div className="column">
                                <MarkdownViewer markdown={post.bodyOne} />
                            </div>
                            <div className="column is-3"></div>
                        </div>
                    </section>

                    <div className="b2b-image-texts">
                        <ImageTexts imageTexts={post.imageTextTwo} />
                    </div>

                    <section className="body-section b2b-body">
                        <div className="b2b-body wrapper columns">
                            <div className="column is-3"></div>

                            <div className="column">
                                <MarkdownViewer markdown={post.bodyTwo} />
                            </div>
                            <div className="column is-3"></div>
                        </div>
                    </section>

                    <div className="b2b-cta wrapper columns color-back has-text-centered">
                        <div className="column is-5"></div>
                        <div className="column">
                            <h3> {post.scheduleAppointment.heading} </h3>
                            <p>{post.scheduleAppointment.blurb}</p>
                            <ButtonGroupMap isCentered buttons={post.scheduleAppointment.buttons} />
                        </div>

                        <div className="column is-5"></div>
                    </div>
                    

                </div>
            </Layout>
        </SharedStateProvider>
    )
}

function ImageTexts({ imageTexts }) {

    const item = imageTexts
    
    if (!imageTexts.hasThisSection) return ""

    return (
        <div className="b2b-image-texts">
            <div className={`columns is-vcentered image-text-section ${!item.textLeft ? "flex-row-reverse" : ""}`}>
                <div className="column is-3"></div>
                <div className="column">
                    <MarkdownViewer markdown={item.text} />
                </div>
                <div className="column is-1"></div>
                <div className="column">
                    <ImageMeta
                        cloudName="nuvolum"
                        publicId={item.imageId}
                        responsive
                        width="auto"
                    />
                </div>
                <div className="column is-3"></div>
            </div>
        </div>
    )
};

export const pageQuery = graphql`
query OpenForCare {
    allUniquePagesJson(filter: {title: {eq: "open-for-care/"}}) {
      nodes {
        heading
        metaDescription
        metaTitle
        searchHeading
        heading
        subHeadingBlurb
        iconOneHeading
        iconOneBlurb
        iconTwoHeading
        iconTwoBlurb
        imageTextOne {
            hasThisSection
            textLeft
            text
            imageId
        }
        imageTextTwo {
            hasThisSection
            textLeft
            text
            imageId
        }
        bodyOne
        bodyTwo
        scheduleAppointment {
            blurb
            buttons {
            button {
                    appearance
                    buttonText
                    destination
                    href
                }
            }
            heading
        }
      }
    }
  }
  `;

export default BackToBusinessPage
